import React from 'react'
import SlickSlider from '../components/buildingBlocks/SlickSlider'
import Layout from '../components/layout'
import Spacer from '../components/uielements/Spacer'
import MainTitle from '../components/uielements/MainTitle'
import { useTranslation } from 'react-i18next'
import InnerTitle from '../components/uielements/InnerTitle'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'

const LocationsPage = () => {
    const { t } = useTranslation()

    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('locations')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('locations')} />
                <MainTitle>{t('locations')}</MainTitle>
                <Spacer top="6" />
                <InnerTitle>{t('city-no-tone')}</InnerTitle>
                <SlickSlider slug="kalamata-slider" />
                <Spacer top="10" />
                <InnerTitle>{t('city-2-no-tone')}</InnerTitle>
                <SlickSlider slug="messini-slider" />
                <Spacer top="10" />
            </Wrapper>
        </Layout>
    )
}

export default LocationsPage
